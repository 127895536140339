import localforage from "localforage";
import {elem, getConfigForComponent, taskId} from "../../config";
import "./FormPage";

const eventAddress = "form-resolved";


/**
 * Form Builder Component
 *
 * @param componentName
 * @returns {Promise<unknown>}
 * @constructor
 */
export default async function Form(configuration) {

    /* Scroll top, when page was opened */
    window.scrollTo(0, 0);

    return new Promise((resolve, err) => {

        localforage.getItem(taskId).then((result) => {

            let newResult = result;

            window.addEventListener(eventAddress, (data) => {

                /* Defensive copy */
                newResult.model = {
                    ...result.model,
                    ...data.detail
                };

                /* Save data */
                localforage.setItem(taskId, newResult).then((res) => {
                    window.removeEventListener(eventAddress, resolve);
                    resolve();
                });

            });

            const {
                translates,
                sections,
                component,
                topVisible=true,
                waveVisible=true} = configuration;

            let formPage = document.createElement(component);


            formPage.i18n = translates;
            formPage.model = result.model;
            formPage.sections = sections;
            formPage.config = {
                topVisible: topVisible,
                waveVisible: waveVisible
            };

            elem.innerHTML = "";
            elem.appendChild(formPage);
        });
    });
}
