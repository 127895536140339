import {css, html, LitElement, svg} from "lit";
import '@vaadin/text-field'
import '@vaadin/button'
import '@vaadin/horizontal-layout'
import '@vaadin/icon'


import {getCurrentGeolocation} from "../../../lib/GeoPermissions";

export default class FieldGeo extends LitElement {

    constructor() {
        super();
        this.value = null;
    }

    static styles = css
        `
         .outlinee {
            display: flex;
            flex-flow: row;
            flex-direction: row; 
            flex-wrap: nowrap; 
            justify-content: space-between;
         }
         vaadin-icon  {
            padding: 0.25rem;
         
         }        
        
        `

    static properties = {
        name: { type: String },
        minlength: { type: Number },
        maxlength: { type: Number },
        label: { type: String },
        value: { type: String },
        readonly: { type: Boolean },
        pattern: { type: String },
        coordinates: { type: Object },
        geo: { type: Object }
    }


    getLocalization() {
        this.geo = '';
        this.coordinates = ''
        getCurrentGeolocation()
            .then(gps => {
                this.geo = `${gps.latitude},${gps.longitude}`;
                this.coordinates = gps;

            })
    }

    validate() {
        const elem = this.shadowRoot.querySelector("#geo-picker");
        elem.validate();
    }


    invalidChanged(e) {
        this.dispatchEvent(
            new CustomEvent("invalid-changed", {
                detail: e.detail,
                bubbles: true,
                composed: true,
            })
        );
    }

    emit(data) {

        this.dispatchEvent(
            new CustomEvent("gps-changed", {
                detail: data.replaceAll(" ", ""),
                bubbles: true,
                composed: true,
            })
        );
    }

    render() {

        const svgGeoIcon = svg`<g id="vaadin:map-marker"><path d="M8 0c-2.8 0-5 2.2-5 5s4 11 5 11c1 0 5-8.2 5-11s-2.2-5-5-5zM8 8c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path></g>`;
        const svgCrossIcon = svg`<g id="vaadin:close-small"><path d="M12.96 4.46l-1.42-1.42-3.54 3.55-3.54-3.55-1.42 1.42 3.55 3.54-3.55 3.54 1.42 1.42 3.54-3.55 3.54 3.55 1.42-1.42-3.55-3.54 3.55-3.54z"></path></g>`;

        return html
            `
                <div class="outline">
                    <vaadin-text-field style="max-width: 100%; width: 100%;"
                                       id="geo-picker"
                                       name="${this.fieldName}"
                                       minlength="${this.minlength}"
                                       maxlength="${this.maxlength}"
                                       label="${this.label}"
                                       value="${this.geo}"
                                       pattern="^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$"
                                       @invalid-changed="${(e) => this.invalidChanged(e)}"
                                       @value-changed="${(e) => this.emit(e.detail.value)}"
                                       required="${this.required}"
                                       .helperText="${this?.helperText}"

                    >
                        <div slot="prefix">
                            <vaadin-button theme="icon tertiary-inline">
                                <vaadin-icon .svg=${svgCrossIcon}
                                             size="16"
                                             @click="${() => this.geo = ''}" ></vaadin-icon>
                            </vaadin-button>
                        </div>
                        <div slot="suffix">
                            <vaadin-button theme="icon tertiary-inline">
                                <vaadin-icon .svg="${svgGeoIcon}" 
                                             size="17"
                                             @click="${() => this.getLocalization()}"></vaadin-icon>
                            </vaadin-button>
                        </div>

                </div>
                </vaadin-text-field>

                </div>
            `
    }
}


window
    .customElements
    .define(
        'field-geo'
        ,
        FieldGeo
    )
;
