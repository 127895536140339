const getCurrentGeolocation = async () => {
	return new Promise((r, e) => {
		navigator.geolocation.getCurrentPosition((position) => {
			r({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			});
		});
	});
};

const checkGeoPermissions = async () => {
	return await navigator.permissions.query({ name: "geolocation" });
};

const checkCameraPermissions = async () => {};

export { getCurrentGeolocation, checkGeoPermissions };
