import {css, html, LitElement} from "lit";
import '@vaadin/text-field'
import '@vaadin/button'
import '@vaadin/horizontal-layout'
import "@vaadin/date-picker";
import "@vaadin/text-area";
import {formatISO} from "date-fns";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import {calendari18n} from '../../../lib/i18n'

export default class FieldDate extends LitElement {
    constructor() {
        super();
    }

    firstUpdated(_changedProperties) {

        super.firstUpdated(_changedProperties);

        /* Check locale */
        let locale = navigator.language.substring(0, 2) || "en";

        const formatDateIso8601 = (dateParts) => {

            const {year, month, day} = dateParts;
            const date = new Date(year, month, day);
            return dateFnsFormat(date, this.rule);
        };

        const parseDateIso8601 = (inputValue) => {
            const date = dateFnsParse(inputValue, this.rule, new Date());
            return {
                day: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear()
            };
        };



        const datePicker = this.shadowRoot.querySelector("#date-picker");

        datePicker.i18n = {
            ...datePicker.i18n,
            formatDate: formatDateIso8601,
            parseDate: parseDateIso8601,
            monthNames: calendari18n[locale].monthNames,
            weekdays: calendari18n[locale].weekdays,
            weekdaysShort: calendari18n[locale].weekdaysShort,
            week: calendari18n[locale].week,
            today: calendari18n[locale].today,
            cancel: calendari18n[locale].cancel,
        };

    }

    static styles = css
        `
         .outline {
            display: flex;
            flex-flow: row;
            flex-direction: row; 
            flex-wrap: nowrap; 
            justify-content: space-between;
         }
        `

    static properties = {
        name: {type: String},
        minlength: {type: Number},
        maxlength: {type: Number},
        label: {type: String},
        value: {type: String},
        readonly: {type: Boolean},
        pattern: {type: String},
        date: {type: String},
        required: {type: Boolean}
    }

    validate(){
        const elem = this.shadowRoot.querySelector("#date-picker");
        elem.validate();
    }

    invalidChanged(e){
        this.dispatchEvent(
            new CustomEvent("invalid-changed", {
                detail: e.detail,
                bubbles: true,
                composed: true,
            })
        );
    }

    emit(data) {
        this.dispatchEvent(
            new CustomEvent("value-changed", {
                detail: data,
                bubbles: true,
                composed: true,
            })
        );
    }

    render() {
        return html
            `
                <div class="outline">

                    <vaadin-date-picker 
                                        id="date-picker"
                                        name="${this.fieldName}"
                                        .max="${this.max}"
                                        .label="${this.label}"
                                        .helperText="${this.helperText}"
                                        @invalid-changed="${(e) => this.invalidChanged(e)}"
                                        @value-changed="${(e) => (this.emit(e.detail.value))}"
                                        value="${this.value}"
                                        .required="${this.required}"
                    ></vaadin-date-picker>
                </div>
                </vaadin-text-field>

                </div>
            `
    }
}


window.customElements.define('field-date', FieldDate);
