export const calendari18n = {
	pl: {
		monthNames: [
			"Styczeń",
			"Luty",
			"Marzec",
			"Kwiecień",
			"Maj",
			"Czerwiec",
			"Lipiec",
			"Sierpień",
			"Wrzesień",
			"Październik",
			"Listopad",
			"Grudzień",
		],
		weekdays: [
			"Niedziela",
			"Poniedziałek",
			"Wtorek",
			"Środa",
			"Czwartek",
			"Piatek",
			"Sobota",
		],
		weekdaysShort: ["Nd", "Pn", "Wt", "Śro", "Czw", "Pt", "So"],
		week: "Tydzień",
		today: "Dzisiaj",
		cancel: "Anuluj",
	},
	en: {
		monthNames: [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		],
		weekdays: [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		],
		weekdaysShort: ["Su", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		week: "Week",
		today: "Today",
		cancel: "Cancel",
	},
	de: {
		monthNames: [
			"Januar",
			"Februar",
			"Marsch",
			"April",
			"Kann",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember",
		],
		weekdays: [
			"Sonntag",
			"Montag",
			"Dienstag",
			"Mittwoch",
			"Donnerstag",
			"Freitag",
			"Samstag",
		],
		weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
		week: "Woche",
		today: "Heute",
		cancel: "Abbrechen",
	},
	fr: {
		monthNames: [
			"Janvier",
			"Février",
			"Mars",
			"Avril",
			"Mai",
			"Juin",
			"Juillet",
			"Août",
			"Septembre",
			"Octobre",
			"Novembre",
			"Décembre",
		],
		weekdays: [
			"Dimanche",
			"Lundi",
			"Mardi",
			"Mercredi",
			"Jeudi",
			"Vendredi",
			"Samedi",
		],
		weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
		week: "La semaine",
		today: "Aujourd'hui",
		cancel: "Annuler",
	},
};
